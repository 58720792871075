.button {
    background-color: #1D2326;
    border: none;
    border-radius: 24px;
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0), inset 0px 0px 4px black, 0px 0px 4px black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    transition: box-shadow 0.1s ease-in-out, background-color 0.1s ease-in-out;
}

.pressedButton {
    background-color: #1B2124;
    box-shadow: inset 0px 0px 8px rgb(0, 0, 0), inset 0px 0px 4px black, 0px 0px 4px black;
    transition: box-shadow 0.1s ease-in-out, background-color 0.1s ease-in-out;
}

.buttonIcon {
    position: absolute;
    width: 24px;
    height: 24px;
    opacity: 1;
    scale: 1;
    transition: opacity 0.3s ease-in-out, scale 0.1s ease-in-out;
}

.hiddenButtonIcon {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.pressedButtonIcon {
    opacity: 0.75;
    scale: 0.97;
    transition: opacity 0.1s ease-in-out, scale 0.1s ease-in-out;
}

.centerButton {
    flex-grow: 1;
    min-width: 48px;
}