.screenOuter {
    position: relative;
    z-index: 3;
    background-color: #0B0C0D;
    border-radius: 16px;
    box-shadow: 0px 0px 4px #000;
    margin-bottom: 16px;
    overflow: hidden;
}

.screenInner {
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 16px 20px 20px;
    transition: margin 0.3s ease-in-out, gap 0.3s ease-in-out;
}

.screenLight {
    position: absolute;
    z-index: 5;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    width: 100%;
    height: 100%;
    top: -0.0001px;
}

@media (max-width: 560px) {
    .screenInner {
        margin: 12px 16px 16px;
        gap: 2px;
    }
}