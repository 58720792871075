@font-face {
    font-family: 'Avenir-Next';
    src: url('../AvenirNext-DemiBold.ttf');
  }

.credits {
    font-family: 'Avenir-Next', sans-serif;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    position: absolute;
    bottom: 16px;
    color: #4C5559;
    margin: 0 24px;
    opacity: 1;
    transition: opacity 0.5s ease-out;
}

.hiddenCredits {
    opacity: 0;
}

.credits a {
    color: #6C7980;
    transition: color 0.2s ease-in-out;
}

.credits a:hover {
    color: #A3B6BF;
}

@media (max-height: 520px) {
    .credits {
        opacity: 0;
        transition: opacity 0.1s ease-out;
    }
}